import React from 'react';
import GalleryNav from './GalleryNav';
import ImageHolder from './ImageHolder';


function GalleryFrameless() {

  const galleryImages = [
    {
      img: require("../images/frameless1.jpg")
    },
    {
      img: require("../images/frameless2.jpg")
    },
    {
      img: require("../images/frameless3.jpg")
    },
    {
      img: require("../images/frameless4.jpg")
    },
    {
      img: require("../images/frameless5.jpg")
    },
    {
      img: require("../images/frameless6.jpg")
    },
    {
      img: require("../images/frameless7.jpg")
    },
    {
      img: require("../images/frameless8.jpg")
    },
    {
      img: require("../images/frameless9.jpg")
    },
    {
      img: require("../images/frameless10.jpg")
    }

  ]
  

  return (
    <div className="gallery">
      <br/>
      <br></br>
    <h1 id='gallery-header'>Frameless Shower Gallery</h1>
    <GalleryNav/>
      <ImageHolder 
        galleryImages={galleryImages}
      />

      <br /><br />
      <div></div>
    </div>
  );
}

export default GalleryFrameless;