import React from 'react';
import '../../App.css';
import FAQS from '../FAQS';
import { TabTitle } from '../../utils/title';

function FAQ() {
  TabTitle('MB Glass - FAQS')
return (
  <FAQS />
)
}

export default FAQ;