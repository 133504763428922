import React from 'react';
import GalleryNav from './GalleryNav';
import ImageHolder from './ImageHolder';



function GallerySemi() {

  const galleryImages = [
    {
      img: require("../images/semi1.jpg")
    },
    {
      img: require("../images/semi2.jpg")
    },
    {
      img: require("../images/semi3.jpg")
    },
    {
      img: require("../images/semi4.jpg")
    },
    {
      img: require("../images/semi5.jpg")
    },
    {
      img: require("../images/semi6.jpg")
    },
    {
      img: require("../images/semi7.jpg")
    },
    {
      img: require("../images/semi8.jpg")
    },
    {
      img: require("../images/semi9.jpg")
    },
    {
      img: require("../images/semi10.jpg")
    },
    {
      img: require("../images/semi11.jpg")
    },
    {
      img: require("../images/semi12.jpg")
    },
    {
      img: require("../images/semi13.jpg")
    },
    {
      img: require("../images/semi14.jpg")
    },
    {
      img: require("../images/semi15.jpg")
    },
    {
      img: require("../images/semi16.jpg")
    },
    {
      img: require("../images/semi17.jpg")
    },
    {
      img: require("../images/semi18.jpg")
    },
    {
      img: require("../images/semi19.jpg")
    },
    {
      img: require("../images/semi20.jpg")
    },
    {
      img: require("../images/semi21.jpg")
    },
    {
      img: require("../images/semi22.jpg")
    },
    {
      img: require("../images/semi23.jpg")
    }
  ]
  

  return (
    <div className="gallery">
      <br/>
      <br></br>
    <h1 id='gallery-header'>Semi-Frameless Shower Gallery</h1>
    <GalleryNav/>
      <ImageHolder 
        galleryImages={galleryImages}
      />

      <br /><br />
      <div></div>
    </div>
  );
}

export default GallerySemi;