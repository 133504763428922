import React from 'react';
import '../App.css';
import { Button1 } from './Button1';
import { Button2 } from './Button2';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <img className='hero-image' traslate src={ require('../components/icons/mbglasslogo.png')} alt='main logo' />
      <p>Where design and function come together seamlessly</p>
      <div className='hero-btns'>
        <Button2
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          Previous Projects
        </Button2>
        <Button1
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          Contact Us!
        </Button1>
      </div>
    </div>
  );
}

export default HeroSection;