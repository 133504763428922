import React from 'react'
import './GalleryMain.css'

function GalleryMain() {

	return (
		<div className='container-gallery' >
		<div class="wrap">


			<a href="gallery/frameless" class="box one">
				<h1> FRAMELESS SHOWERS</h1>
				<div class="poster p1">
				</div>
			</a>


			<a href="gallery/semi" class="box two">
				<h1>SEMI-FRAMED SHOWERS</h1>
				<div class="poster p2"></div>
			</a>

			< a href="gallery/sliding" class="box three">
				<h1>SLIDING SHOWERS</h1>
				<div class="poster p3">
				</div></a>

			<a href="gallery/mirrors" class="box five">
				<h1>MIRRORS</h1>
				<div class="poster p4">
				</div>
			</a>

			<a href="gallery/misc" class="box six">
				<h1>MISCELLANEOUS</h1>
				<div class="poster p5">
				</div>
			</a>

		</div>
		</div>
	)
}

export default GalleryMain;