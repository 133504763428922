import React from 'react'
import '../../App.css';
import GalleryMirrors from '../GalleryMirrors';
import { TabTitle } from '../../utils/title';

function Mirrors() {
  TabTitle('MB Glass - Mirrors')

  return (
    <>
      <GalleryMirrors  />
    </>
  )
}

export default Mirrors;