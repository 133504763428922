import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>CHECK OUT OUR AMAZING PROJECTS!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={ require('../images/mirror3.jpg')}
              text='We can provide mirrors cut to nearly any shape or design.'
              label='Mirrors'
              path='/gallery/mirrors'
              alt='Mirror'
            />
            <CardItem
              src={ require('../images/frameless1.jpg') }
              text='See our deluxe frameless shower enclosures.'
              label='Showers'
              path='/gallery/semi'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={ require('../images/wine1.jpg') }
              text='We create a personalized look for your space today.'
              label='Wine Case'
              path='/gallery/misc'
            />
            <CardItem
              src={ require('../images/window1.jpg') }
              text='We provide custom windows to compliment any home or business.'
              label='Windows'
              path='/gallery/misc'
            />
            <CardItem
              src={ require('../images/patio1.jpg') }
              text='We can install, repair, or replace your patio doors too.'
              label='Patio Doors'
              path='/gallery/misc'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;