import React from 'react';
import '../App.css';
import './FAQ.css';

function FAQS() {
  return (
    <div>
      <div className='faq-container'>
        <div className='faq-inner-container'>
          <div className='faq-item'>
            <h1 className='FAQ-header'>Frequently Asked Questions</h1>
          </div>
          <div className='faq-item'>
            <h2>What areas of Chicagoland do you service?</h2>
<div className='locations'>
<ul>
  <li>Berwyn</li>
  <li>Bolingbrook</li>
  <li>Brookefield</li>
  <li>Chicago</li>
  <li>Cicero</li>
  <li>Carol Stream</li>
  <li>Glen Ellyn</li>
  <li>Hillside</li>
  </ul>
  <ul>
  <li>Hindsdale</li>
  <li>La Grange</li>
  <li>Lemont</li>
  <li>Lisle</li>
  <li>Lombard</li>
  <li>Naperville</li>
  <li>Oak Park</li>
  <li>Wheaton</li>
  <li>and more!</li>
  </ul>
  </div>
          </div>
          <div className='faq-item'>
            <h2>Are estimates free?</h2>
            <p>Yes, MB Glass LLC offers FREE consultations for your home or place of business.
            </p>
          </div>
          <div className='faq-item'>
            <h2>Is MB Glass LLC a licensed & bonded company?</h2>
            <p> Yes, MB Glass LLC is a licensed, bonded, and insured company.</p>
          </div>
          <div className='faq-item'>
            <h2>What are your hours of operator?</h2>
            <p>Monday - Friday</p>
            <p> 8:00 AM - 5:00 PM</p>
          </div>
          <div className='faq-item'>
            
            <h2>How do we schedule an estimate?</h2>
            <p>Fill out the contact form on our Contacts page or reach us by phone or email! </p>
                    </div>
                    <div className='faq-item'>
            
            <h2>What hardware colors do you offer?</h2>
            <p> Our most common hardware colors are Polished Chrome, Brushed Nickel, Black, and Satin Brass, but we can accomodate a wider range of finishes upon request.
            </p>
                    </div>

          <div className='faq-item'>
            <h2>What is the difference between a frameless and semi-frameless shower?</h2>
            <p>Frameless showers are used with miminal mounting hardware. Typically they're installed with clamps rather than a continous u-channel.
            </p>
          </div>
          <div className='faq-item'>
            <h2>How long does an estimate take?</h2>
            <p>Most residential estimates are completed in less than an hour. Our estimators are professional and efficient. </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQS;