import React from 'react'
import '../../App.css';
import GalleryFrameless from '../GalleryFrameless.js';
import { TabTitle } from '../../utils/title';



function Frameless() {
  TabTitle('MB Glass - Frameless Showers')

  return (
    
    <>
<GalleryFrameless/>
    </>

  )
}

export default Frameless;