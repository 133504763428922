import React from 'react'
import './GalleryNav.css'

function GalleryNav() {
    return (
        <>
            <div className='link-container'>
                <a className='link-gallery' href='/gallery/frameless' >Framless Showers</a>
                <a className='link-gallery' href='/gallery/semi' >Semi-Frameless Showers</a>
                <a className='link-gallery' href='/gallery/sliding' >Sliding Showers</a>
                <a className='link-gallery' href='/gallery/mirrors' >Mirrors</a>
                <a className='link-gallery' href='/gallery/misc' >Miscellaneous</a>
            </div>
        </>


    )
}

export default GalleryNav;