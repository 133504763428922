import React from 'react';
import './Footer.css';




function Footer() {
    return (

        <footer class="site-footer">
            <div class="footer-item">
                <h4>links</h4>
                <ul class="footer-links">

                    <li><a href="/contact">Contact Us</a></li>
                </ul>
            </div>
            <h4>&copy; 2022 MB Glass & Mirror LLC</h4>
            <div class="footer-item">
                <ul class="social-icons">
                <a
                href={"https://www.facebook.com/mbglassandmirror"}
                target='_blank'
                rel='noopener noreferrer'
                class='social-icon-link facebook'
                aria-label='Facebook'
              >
                <i class='fab fa-facebook' />
              </a>
              <a
                class='social-icon-link instagram'
                href='https://www.instagram.com/mb_glass_mirror'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Instagram'
              >
                <i class='fab fa-instagram' />
              </a>
                </ul>
            </div>



        </footer>
    );
}






export default Footer;