import React from 'react';
import GalleryNav from './GalleryNav';
import ImageHolder from './ImageHolder';


function GalleryMisc() {

  const galleryImages = [
    {
      img: require("../images/wine1.jpg")
    },
    {
      img: require("../images/wine2.jpg")
    },
    {
      img: require("../images/misc1.jpg")
    },
    {
      img: require("../images/misc2.jpg")
    },
    {
      img: require("../images/misc3.jpg")
    },
    {
      img: require("../images/misc4.jpg")
    },
    {
      img: require("../images/misc5.jpg")
    },
    {
      img: require("../images/misc6.jpg")
    },
    {
      img: require("../images/misc7.jpg")
    },
    {
      img: require("../images/misc8.jpg")
    },
    {
      img: require("../images/misc9.jpg")
    }
  ]
  

  return (
    <div className="gallery">
      <br/>
      <br></br>
    <h1 id='gallery-header'>Miscellaneous Gallery</h1>
    <GalleryNav/>
      <ImageHolder 
        galleryImages={galleryImages}
      />

      <br /><br />
      <div></div>
    </div>
  );
}

export default GalleryMisc;