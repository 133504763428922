import React from 'react';
import GalleryNav from './GalleryNav';
import ImageHolder from './ImageHolder';



function GallerySliding() {

  const galleryImages = [
    {
      img: require("../images/sliding1.jpg")
    },
    {
      img: require("../images/sliding2.jpg")
    },
    {
      img: require("../images/sliding3.jpg")
    },
    {
      img: require("../images/sliding4.jpg")
    },
    {
      img: require("../images/sliding5.jpg")
    },
  ]
  

  return (
    <div className="gallery">
      <br/>
      <br></br>
    <h1 id='gallery-header'>Sliding Shower Gallery</h1>
    <GalleryNav/>
      <ImageHolder 
        galleryImages={galleryImages}
      />

      <br /><br />
      <div></div>
    </div>
  );
}

export default GallerySliding;