
import React from 'react';
import '../../App.css';
import { TabTitle } from '../../utils/title';
import Cards from '../Cards';
import HeroSection from '../HeroSection.js';

function Home() {
  TabTitle('MB Glass')
  return (
    <>
      <HeroSection />
      <Cards />
    </>
  );
}

export default Home;