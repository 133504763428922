import React from 'react';
import '../../App.css';
import ContactForm from '../ContactForm';
import { TabTitle } from '../../utils/title';


function Contact() {
  TabTitle('MB Glass - Contact')

  return (
    <>
      <ContactForm />
    </>
  );
}

  export default Contact;