import React from 'react'
import '../../App.css';
import { TabTitle } from '../../utils/title';
import GallerySliding from '../GallerySliding';

function Sliding() {
  TabTitle('MB Glass - Sliding Showers')

  return (
    <div className='galleryBackground'>
    <>
      <GallerySliding  />
    </>
    </div>
  )
}

export default Sliding;