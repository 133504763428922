import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css'


function ContactForm() {

  const form = useRef(null);
    
  const sendEmail = (e) => {
      e.preventDefault();
          
      emailjs.sendForm('service_agwv3kr', 'template_xl7t0kn', form.current, '_ANSfvZI_4KCGStW0')
      .then((result) => {
          console.log(result.text);
          alert("Email Sent!");
      }, (error) => {
          console.log(error.text);
      });

 
  };

  return (

    <div className='container'>
      <div className='contact-whole'>
        <div className='contact-submit'>
          <h2>Get a quote today!</h2>
          
          <form ref={form} onSubmit={sendEmail} id="contact-form"  >
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" placeholder='Name - required' name='Name' required />
            </div>
            <div className="form-group">
              <label htmlFor='tel' >Phone number</label>
              <input type="tel" className="form-control" aria-describedby="telHelp" placeholder='Phone number - required' name='Phone' required />
            </div>
            <div className="form-group">
              <label htmlFor='Email'>Email address</label>
              <input type="email" className="form-control" aria-describedby="emailHelp" placeholder='Email here' name='Email'/>
            </div>
            <div className="form-group">
              <label htmlFor='Message'>Message</label>
              <textarea className="form-control" name='Message' rows="defaultValue" placeholder='Enter a message' ></textarea>
            </div>

            <button type="submit" value="Send" id='submit-button'  className="btn btn-primary">Submit</button>
          </form>
        </div>
        <div className='contact-info'>
          <h2>Get in Touch</h2>
          <div className='contact-phone' >
            <div className='svg-container'>
              <img className='svg-contact' src={require('../components/icons/phone.svg')} alt='phone icon'  />
              </div>
              <a href='tel:+1-630-440-2975'>(630) 440-2975</a>
          </div>

          <div className='contact-email' >
            <div className='svg-container'>
              <img className='svg-contact' src={require('../components/icons/evelope.svg')} alt='email' />
              </div>
              <a href="mailto: mikeglassandmirror@gmail.com" id='email-tag' >MikeGlassandMirror@gmail.com</a>
            </div>

          <ul class="social-icons">
            <row>
              <a
                href={"https://www.facebook.com/mbglassandmirror"}
                target='_blank'
                rel='noopener noreferrer'
                class='social-icon-link facebook'
                aria-label='Facebook'
              >
                <i class='fab fa-facebook' />
              </a>
              <a
                class='social-icon-link instagram'
                href='https://www.instagram.com/mb_glass_mirror'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Instagram'
              >
                <i class='fab fa-instagram' />
              </a>
            </row>
          </ul>
          <ul className='hours'>
            <h2>Business Hours</h2>
            <li>Monday: 8:00 AM - 5:00 PM</li>
            <li>Tuesday: 8:00 AM - 5:00 PM</li>
            <li>Wednesday: 8:00 AM - 5:00 PM</li>
            <li>Thursday: 8:00 AM - 5:00 PM</li>
            <li>Friday: 8:00 AM - 5:00 PM</li>
            <li>Weekends: By appointment</li>
          </ul>

        </div>
      </div>
    </div>


  )
};

export default ContactForm;