import React from 'react'
import '../../App.css';
import GalleryMisc from '../GalleryMisc';
import { TabTitle } from '../../utils/title';



function Misc() {
    TabTitle('MB Glass - Miscellanous')

  return (
    
    <>
<GalleryMisc/>
    </>

  )
}

export default Misc;