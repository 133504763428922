import React from 'react';
import GalleryMain from '../GalleryMain';
import { TabTitle } from '../../utils/title';

function Mirrors() {
  TabTitle('MB Glass - Gallery')

  return(
<>
  <GalleryMain />
  </>
  )
}

export default Mirrors;