import React from 'react';
import '../../App.css';
import Service from '../Service';
import { TabTitle } from '../../utils/title';

function Services() {
  TabTitle('MB Glass - Services')

  return(
<>
  <Service />
  </>)
}

export default Services;