import React from 'react';
import './Service.css'




function Service() {

  return (
    <div className='container-services'>
      <div className='header-container'>
        <h1>Services</h1>
        <p></p>
      </div>
      <div className='card-container'>
        <div className='card-item-1'>
          <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128"><path d="M115 84.079H13a1.75 1.75 0 0 0-1.75 1.75v17.946a1.75 1.75 0 0 0 1.75 1.75h102a1.75 1.75 0 0 0 1.75-1.75V85.829a1.75 1.75 0 0 0-1.75-1.75zm-1.75 17.946H102.2v-7.909a1.75 1.75 0 0 0-3.5 0v7.909h-8.648v-4.98a1.75 1.75 0 0 0-3.5 0v4.98H77.9v-7.909a1.75 1.75 0 0 0-3.5 0v7.909h-8.65v-4.98a1.75 1.75 0 0 0-3.5 0v4.98H53.6v-7.909a1.75 1.75 0 0 0-3.5 0v7.909h-8.652v-4.98a1.75 1.75 0 0 0-3.5 0v4.98H29.3v-7.909a1.75 1.75 0 0 0-3.5 0v7.909H14.75V87.579h98.5zM90.042 60.142a10.958 10.958 0 1 0-10.959-10.959 10.971 10.971 0 0 0 10.959 10.959zm0-18.417a7.458 7.458 0 1 1-7.459 7.458 7.467 7.467 0 0 1 7.459-7.458zM72.625 69.558h5.625a1.75 1.75 0 0 0 1.75-1.75v-5.625a1.75 1.75 0 0 0-1.75-1.75h-5.625a1.75 1.75 0 0 0-1.75 1.75v5.625a1.75 1.75 0 0 0 1.75 1.75zm1.75-5.625H76.5v2.125h-2.125z" /><path d="M13 72.533h44.917v1.608a1.75 1.75 0 0 0 1.75 1.75h30.375a26.708 26.708 0 1 0-26.709-26.708v11.25h-3.666a1.75 1.75 0 0 0-1.75 1.75v1.782H14.75v-.89a1.75 1.75 0 0 0-3.5 0v7.708a1.75 1.75 0 0 0 1.75 1.75zm53.833-23.35a23.208 23.208 0 1 1 23.209 23.209H66.833zm-5.417 14.75h1.917v8.458h-1.916v-8.458zm-3.5 3.532v1.568H14.75v-1.568z" /></svg>
          <h2>Expert measurements</h2>
          <p>Our expert team offers professional and precise estimates to improve your home installation process. We do our best to provide the highest degree of professionalism and quality during every installation.</p>       </div>
        <div className='card-item-2'>
          <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 497.56"><path d="M0 465.72h512v31.84H0v-31.84zM179.53 96.06c2.29-2.95 6.55-3.48 9.5-1.19a6.77 6.77 0 0 1 1.19 9.51l-23.11 29.71a6.778 6.778 0 0 1-9.51 1.19c-2.95-2.29-3.48-6.55-1.18-9.5l23.11-29.72zm-50.58-16.75c2.29-2.95 6.55-3.48 9.5-1.19 2.95 2.3 3.49 6.56 1.19 9.51l-23.11 29.71c-2.29 2.95-6.55 3.48-9.5 1.19-2.95-2.3-3.49-6.56-1.19-9.51l23.11-29.71zm39.44-8.45a6.788 6.788 0 0 1 9.51-1.19 6.789 6.789 0 0 1 1.19 9.51l-52.79 67.86c-2.29 2.95-6.55 3.48-9.5 1.19-2.95-2.3-3.49-6.56-1.19-9.51l52.78-67.86zm223.9 25.2a6.789 6.789 0 0 1 9.51-1.19 6.789 6.789 0 0 1 1.19 9.51l-23.12 29.71c-2.29 2.95-6.55 3.49-9.5 1.19-2.95-2.29-3.49-6.55-1.19-9.5l23.11-29.72zm-50.58-16.75c2.29-2.95 6.55-3.48 9.5-1.19 2.95 2.3 3.49 6.56 1.19 9.51l-23.11 29.71c-2.29 2.95-6.55 3.48-9.5 1.19-2.95-2.3-3.49-6.56-1.19-9.51l23.11-29.71zm39.44-8.45a6.789 6.789 0 0 1 9.51-1.19 6.789 6.789 0 0 1 1.19 9.51l-52.78 67.86a6.789 6.789 0 0 1-9.51 1.19 6.789 6.789 0 0 1-1.19-9.51l52.78-67.86zm-83.37 136.69-17.69-5.06v66.15l17.69-6.39v-54.7zm-86.74 0 17.7-5.06v66.15l-17.7-6.39v-54.7zM248.1 20.89v438.38h12.62V20.89H248.1zm-187.33 0h390.75v438.38h22.32V0H34.98v459.27H57.3V20.89h3.47z" /></svg>
          <h2>Shower Enclosure Installation</h2>
          <p>Our team offers unique personalized shower enclosures for every situation. Whether you want a modern look or need a shower enclosure for special needs, our team is here to provide you with endless options to complete your bathroom. For all your needs, we offer a variety of styles and types.</p>
        </div>
        <div className='card-item-3'>
          <img className='svg-icon' alt='table top' src={require('../components/icons/dining-table.svg')} />
          <h2>Custom Table tops</h2>
          <p>Get custom glass tops for tables, dressers, and many other surfaces. We can provide expertly crafted tops to in a variety of shapes, sizes, colors, and thicknesses. </p>
        </div>
        <div className='card-item-4'>
          <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><defs><style>.cls-1 fill:#231f20;</style></defs><g id="mirror"><path class="cls-1" d="M6.5,22h12a.5.5,0,0,0,.5-.5V1.5a.5.5,0,0,0-.5-.5H6.5a.5.5,0,0,0-.5.5v20A.5.5,0,0,0,6.5,22ZM7,2H18V21H7Z" /><path class="cls-1" d="M16.5,3h-8a.5.5,0,0,0-.5.5v16a.5.5,0,0,0,.5.5h8a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,16.5,3ZM16,19H9V4h7Z" /><path class="cls-1" d="M20.5,12h-.56a.5.5,0,1,0,0,1H20V23H5V13a.5.5,0,0,0,0-1H4.5a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,.5.5h16a.5.5,0,0,0,.5-.5v-11A.5.5,0,0,0,20.5,12Z" /><path class="cls-1" d="M10.5,8a.47.47,0,0,0,.35-.15l1-1a.49.49,0,0,0-.7-.7l-1,1a.48.48,0,0,0,0,.7A.47.47,0,0,0,10.5,8Z" /><path class="cls-1" d="M10.5,10a.47.47,0,0,0,.35-.15l3-3a.49.49,0,0,0-.7-.7l-3,3a.48.48,0,0,0,0,.7A.47.47,0,0,0,10.5,10Z" /></g></svg>
          <h2>Mirror installation</h2>
          <p>Our team can provide custom mirrors in various shapes, sizes, and colors. We can accomodate full-length gym mirrors, stylish antique mirrors to bring your space to the next level, and nearly anything you can think of.</p>
        </div>
        <div className='card-item-5'>
          <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><defs><style>.cls-1; fill:#231f20</style></defs><g id="window"><path class="cls-1" d="M23.5 21H23V2.21A1.21 1.21 0 0 0 21.79 1H3.21A1.21 1.21 0 0 0 2 2.21V21h-.5a.5.5 0 0 0-.5.5v1.59a.91.91 0 0 0 .91.91h21.18a.91.91 0 0 0 .91-.91V21.5a.5.5 0 0 0-.5-.5zM3 2.21A.21.21 0 0 1 3.21 2h18.58a.21.21 0 0 1 .21.21V21h-1V4a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v17H3zM13 4h7v17h-7zm-1 17H5V4h7zm11 2H2v-1h21z" /><path class="cls-1" d="M7.5 8.7a.52.52 0 0 0 .35-.14l1.25-1.2a.5.5 0 1 0-.7-.72l-1.25 1.2a.5.5 0 0 0 .35.86zM7.5 10.5a.52.52 0 0 0 .35-.14L10.35 8a.5.5 0 0 0-.7-.72l-2.5 2.4a.5.5 0 0 0 .35.86zM15.5 8.7a.52.52 0 0 0 .35-.14l1.25-1.2a.5.5 0 1 0-.7-.72l-1.25 1.2a.5.5 0 0 0 .35.86zM17.65 7.24l-2.5 2.4a.5.5 0 0 0 .35.86.52.52 0 0 0 .35-.14L18.35 8a.5.5 0 1 0-.7-.72z" /></g></svg>
          <h2>Luxury Office Spaces</h2>
          <p>We understand the importance of providing a safe and comfortable environment for your employees, customers and visitors. Our professional glass installers are trained to provide excellent service to the highest standards of quality and performance. </p>
        </div>
        <div className='card-item-6'>
          <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><title>repair</title><g id="toolbox" data-name="toolbox"><path d="M30.56,8.47a8,8,0,0,0-7-7,64.29,64.29,0,0,0-15.06,0,8,8,0,0,0-7,7,64.29,64.29,0,0,0,0,15.06,8,8,0,0,0,7,7,64.29,64.29,0,0,0,15.06,0,8,8,0,0,0,7-7A64.29,64.29,0,0,0,30.56,8.47ZM14,28.94V19.22c0-2.71-3-2.87-3-4.23V9.29c0-1,1.11-1,2-1.54V10a3,3,0,0,0,6,0V7.75c.85.49,2,.56,2,1.54V15c0,1.35-3,1.51-3,4.23v9.72C16.67,29,15.33,29,14,28.94ZM28.58,23.3a6,6,0,0,1-5.28,5.28c-1.1.12-2.2.21-3.3.28V19.22C20,18,23,17.64,23,15V9.29c0-2.82-3.17-3.13-4.59-4A1,1,0,0,0,17,6.2V10a1,1,0,0,1-2,0V6.2a1,1,0,0,0-1.41-.91C12.18,6.16,9,6.51,9,9.29V15c0,2.67,3,3,3,4.23v9.64c-1.1-.07-2.2-.16-3.3-.28A6,6,0,0,1,3.42,23.3a63.65,63.65,0,0,1,0-14.6A6,6,0,0,1,8.7,3.42a63.65,63.65,0,0,1,14.6,0A6,6,0,0,1,28.58,8.7,63.65,63.65,0,0,1,28.58,23.3Z" /></g></svg>
          <h2>Shower Repair</h2>
          <p>MB Glass can replace damaged glass, hardware, or vinyl for your shower enclosures. Should you need the removal or re-installation of any shower door, our staff will provide professional and timely results. </p>
        </div>

      </div>
    </div>
  );
}

export default Service;