import React from 'react';
import Navbar from './components/Navbar'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Gallery from './components/pages/Gallery';
import Contact from './components/pages/Contact';
import FAQ from './components/pages/FAQ';
import Footer from './components/Footer';
import Mirrors from './components/pages/Mirrors';
import Frameless from './components/pages/Frameless';
import Semi from './components/pages/Semi';
import Sliding from './components/pages/Sliding';
import Misc from './components/pages/Misc';

function App() {
  return (
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={< Home />} />
          <Route path='/services' element={< Services />} />
          <Route path='/contact' element={< Contact />} />
          <Route path='/faq' element={< FAQ />} />
          <Route path='/gallery' element={< Gallery />} />
          <Route path='/gallery/mirrors' element={< Mirrors />} />
          <Route path='/gallery/frameless' element={< Frameless />} />
          <Route path='/gallery/semi' element={< Semi />} />
          <Route path='/gallery/sliding' element={< Sliding />} />
          <Route path='/gallery/misc' element={< Misc />} />

        </Routes>
        <Footer />
        
      </Router>
  );
}

export default App;
