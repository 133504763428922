import React from 'react';
import GallerySemi from '../GallerySemi';
import { TabTitle } from '../../utils/title';

function Semi() {
  TabTitle('MB Glass - Semi Frameless Showers')

  return(
<>

  <GallerySemi />
  </>
  )
}

export default Semi;