import React from 'react';
import ImageHolder from './ImageHolder';
import './GalleryDisplay.css';
import GalleryNav from './GalleryNav';



function GalleryMirrors() {

  const galleryImages = [
    {
      img: require("../images/mirror1.jpg")
    },
    {
      img: require("../images/mirror2.jpg")
    },
    {
      img: require("../images/mirror3.jpg")
    },
    {
      img: require("../images/mirror4.jpg")
    },
    {
      img: require("../images/mirror5.jpg")
    },
    {
      img: require("../images/mirror6.jpg")
    },
    {
      img: require("../images/mirror7.jpg")
    },
    {
      img: require("../images/mirror8.jpg")
    },
    {
      img: require("../images/mirror9.jpg")
    },
    {
      img: require("../images/mirror10.jpg")
    },
    {
      img: require("../images/mirror11.jpg")
    },
    {
      img: require("../images/mirror12.jpg")
    },
    {
      img: require("../images/mirror13.jpg")
    },
    {
      img: require("../images/mirror14.jpg")
    },
    {
      img: require("../images/mirror15.jpg")
    },
    {
      img: require("../images/mirror16.jpg")
    },
    {
      img: require("../images/mirror17.jpg")
    },
    {
      img: require("../images/mirror18.jpg")
    },
    {
      img: require("../images/mirror19.jpg")
    },
    {
      img: require("../images/mirror20.jpg")
    },
  ]
  

  return (
    <div  className="gallery">
      <br/>
      <br></br>
    <h1 id='gallery-header'>Mirror Gallery</h1>
    <GalleryNav/>
      <ImageHolder 
        galleryImages={galleryImages}
      />

      <br /><br />
      <div></div>
    </div>
  );
}

export default GalleryMirrors;